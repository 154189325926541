import React, { Component } from 'react';
import { Form, Row, Col, Label, Input, Button } from 'reactstrap';
import { request } from './request.js';
import BarcodeReader from 'react-barcode-reader';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './App.css';

class App extends Component 
{

	constructor(props)
	{
		super(props)
		this.state = {
			ficha :
			{
				id : '',
				num_control : '',
				nombre : '',
				apellido_paterno : '',
				apellido_materno : '',
				credencial : ''
			},
			encontrado : false
		}

		this.entregar_credencial = this.entregar_credencial.bind(this);
	}

	handleInputChange=(event)=>
	{
		const value = event.target.value;
		var { ficha } = this.state;
		var codigo='';
		codigo = value[value.length-1];
		if(codigo === 'a')
		{
			this.entregar_credencial();
		}
		else
		{	
			ficha['num_control'] = value;
		}
		this.setState({ ficha : ficha });
	}

	entregar_credencial()
	{
		var { ficha, encontrado } = this.state;
		let temp = this;

		request.get(`/EntregarCredencial/${ficha.num_control}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					ficha = response.data;
					ficha.num_control="";
					encontrado=true;

					temp.setState({ ficha : ficha, encontrado : encontrado });
				}
				else
				{
					encontrado=false;
					ficha.num_control="";
					ficha.nombre = "";
					ficha.apellido_materno="";
					ficha.apellido_paterno="";
					ficha.credencial=0;
					temp.setState({ ficha : ficha, encontrado : encontrado });
				}
			}
		})
		.catch(error => {} );

		this.setState({ ficha : ficha, encontrado : encontrado });
	}

	render()
	{
		let { ficha, encontrado } = this.state;

		return (
			<div className="back">
				<div className="main fluid-container text-center">
					<Row className="pl-1 pr-1 justify-content-around">
						<Col className="col-12 col-md-10 col-lg-8 col-xl-6">
							<div className="pl-2 pr-1 loginForm">
								<Label className="text-center"> <h4> Control de Credenciales </h4> </Label>
								<Form>
									<Row>
										<Col xs="12">
											<BarcodeReader onError={this.handleError} onScan={this.handleScan} />						
										</Col>
									</Row>
									<Row>
										<Col xs="3">
											<Label for="ficha"> Numero de control:</Label>
										</Col>
										<Col xs="6">
											<Input className="texto inputs" type="text" name="num_control" value={ ficha.num_control } onChange={this.handleInputChange} />
										</Col>
										<Col xs="3">
											<Button className="text-center texto" onClick={this.entregar_credencial}> BUSCAR </Button>
										</Col>
									</Row>
									<Row className="pt-2">
										<Col xs="3">
											<Label for="nombre"> Nombre:</Label>
										</Col>
										<Col xs="8">
											{
												encontrado === true ?
													<Input className="texto inputs cap" type="text" name="nombre" value={ ficha.nombre + " " + ficha.apellido_paterno + " " + ficha.apellido_materno  } disabled />
												:
													<Input className="texto inputs cap" type="text" name="nombre" value={""} disabled />	
											}	
										</Col>
									</Row>
									<Row className="pt-2">
										<Col xs="3">
											<Label for="encontrado"> Estado: </Label>
										</Col>
										<Col xs="6">
											{	
												encontrado === false ?
													<Input className="texto inputs" type="text" name="encontrado" value="NO ENCONTRADO" disabled />
												:
													parseInt(ficha.credencial,10) === 1 ? 
														<Input className="texto inputs" type="text" name="encontrado" value="Entrega Registrada" disabled />
													:
														<Input className="texto inputs" type="text" name="encontrado" value={ "CANCELACION" } disabled />	
												}
												
										</Col>
									</Row>
									
								</Form>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default App;